
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_91userId_93_93hdYzocb2qtMeta } from "/app/packages/frontend-bo/src/pages/comptes/[[userId]].vue?macro=true";
import { default as liste_45organismeV18g5ASgcfMeta } from "/app/packages/frontend-bo/src/pages/comptes/liste-organisme.vue?macro=true";
import { default as listeqO9DmsJiZ9Meta } from "/app/packages/frontend-bo/src/pages/comptes/liste.vue?macro=true";
import { default as mon_45compteQgX0PpJgohMeta } from "/app/packages/frontend-bo/src/pages/comptes/mon-compte.vue?macro=true";
import { default as indexByT8uJ8JF7Meta } from "/app/packages/frontend-bo/src/pages/connexion/index.vue?macro=true";
import { default as mot_45de_45passe_45oublied7yhgh8CtJMeta } from "/app/packages/frontend-bo/src/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as reset_45mot_45de_45passevU5azHZf57Meta } from "/app/packages/frontend-bo/src/pages/connexion/reset-mot-de-passe.vue?macro=true";
import { default as validationMfRfhGQSWvMeta } from "/app/packages/frontend-bo/src/pages/connexion/validation.vue?macro=true";
import { default as _91eigId_93sbC1GtiqR4Meta } from "/app/packages/frontend-bo/src/pages/eig/[eigId].vue?macro=true";
import { default as indexRqbChnOCBfMeta } from "/app/packages/frontend-bo/src/pages/eig/index.vue?macro=true";
import { default as accessibilite1c2b4yieqAMeta } from "/app/packages/frontend-bo/src/pages/footer/accessibilite.vue?macro=true";
import { default as donnees_45personnelleseCiq28MKuIMeta } from "/app/packages/frontend-bo/src/pages/footer/donnees-personnelles.vue?macro=true";
import { default as gestion_45cookiesfABNU63PrnMeta } from "/app/packages/frontend-bo/src/pages/footer/gestion-cookies.vue?macro=true";
import { default as mentions_45legalesf3bYZmMwgWMeta } from "/app/packages/frontend-bo/src/pages/footer/mentions-legales.vue?macro=true";
import { default as _91hebergementId_93fjgi9dU1m2Meta } from "/app/packages/frontend-bo/src/pages/hebergements/[type]/[demandeSejourId]/[hebergementId].vue?macro=true";
import { default as _91hebergementId_933fhTUqPKpGMeta } from "/app/packages/frontend-bo/src/pages/hebergements/[type]/[hebergementId].vue?macro=true";
import { default as indexhJqaX9oOeQMeta } from "/app/packages/frontend-bo/src/pages/hebergements/[type]/index.vue?macro=true";
import { default as indexwWppGIjTIhMeta } from "/app/packages/frontend-bo/src/pages/hebergements/index.vue?macro=true";
import { default as indexQAbM7tiAcvMeta } from "/app/packages/frontend-bo/src/pages/index.vue?macro=true";
import { default as indexImCSGNmFq7Meta } from "/app/packages/frontend-bo/src/pages/informations/index.vue?macro=true";
import { default as indexbUK8nsahbMMeta } from "/app/packages/frontend-bo/src/pages/messagerie/index.vue?macro=true";
import { default as _91_91organismeId_93_93pbpOPlw7sIMeta } from "/app/packages/frontend-bo/src/pages/organismes/[[organismeId]].vue?macro=true";
import { default as listeYOkek1fj4EMeta } from "/app/packages/frontend-bo/src/pages/organismes/liste.vue?macro=true";
import { default as documentsZWlI4gTDJMMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId]/documents.vue?macro=true";
import { default as eigSNImRKCJuBMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId]/eig.vue?macro=true";
import { default as formulaireysnolVVU0dMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId]/formulaire.vue?macro=true";
import { default as historiqueTyejSwDwHSMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId]/historique.vue?macro=true";
import { default as messageriepeImfSGjtoMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId]/messagerie.vue?macro=true";
import { default as _91declarationId_937n92zEX3FvMeta } from "/app/packages/frontend-bo/src/pages/sejours/[declarationId].vue?macro=true";
import { default as indexJOAtVRTNQwMeta } from "/app/packages/frontend-bo/src/pages/sejours/index.vue?macro=true";
import { default as _91_91territoireId_93_93wXUagTSvEGMeta } from "/app/packages/frontend-bo/src/pages/territoires/[[territoireId]].vue?macro=true";
import { default as listenKXtYhESagMeta } from "/app/packages/frontend-bo/src/pages/territoires/liste.vue?macro=true";
export default [
  {
    name: "comptes-userId",
    path: "/comptes/:userId?",
    meta: _91_91userId_93_93hdYzocb2qtMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/comptes/[[userId]].vue")
  },
  {
    name: "comptes-liste-organisme",
    path: "/comptes/liste-organisme",
    meta: liste_45organismeV18g5ASgcfMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/comptes/liste-organisme.vue")
  },
  {
    name: "comptes-liste",
    path: "/comptes/liste",
    meta: listeqO9DmsJiZ9Meta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/comptes/liste.vue")
  },
  {
    name: "comptes-mon-compte",
    path: "/comptes/mon-compte",
    meta: mon_45compteQgX0PpJgohMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/comptes/mon-compte.vue")
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/app/packages/frontend-bo/src/pages/connexion/index.vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "/connexion/mot-de-passe-oublie",
    component: () => import("/app/packages/frontend-bo/src/pages/connexion/mot-de-passe-oublie.vue")
  },
  {
    name: "connexion-reset-mot-de-passe",
    path: "/connexion/reset-mot-de-passe",
    component: () => import("/app/packages/frontend-bo/src/pages/connexion/reset-mot-de-passe.vue")
  },
  {
    name: "connexion-validation",
    path: "/connexion/validation",
    component: () => import("/app/packages/frontend-bo/src/pages/connexion/validation.vue")
  },
  {
    name: "eig-eigId",
    path: "/eig/:eigId()",
    meta: _91eigId_93sbC1GtiqR4Meta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/eig/[eigId].vue")
  },
  {
    name: "eig",
    path: "/eig",
    meta: indexRqbChnOCBfMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/eig/index.vue")
  },
  {
    name: "footer-accessibilite",
    path: "/footer/accessibilite",
    component: () => import("/app/packages/frontend-bo/src/pages/footer/accessibilite.vue")
  },
  {
    name: "footer-donnees-personnelles",
    path: "/footer/donnees-personnelles",
    component: () => import("/app/packages/frontend-bo/src/pages/footer/donnees-personnelles.vue")
  },
  {
    name: "footer-gestion-cookies",
    path: "/footer/gestion-cookies",
    component: () => import("/app/packages/frontend-bo/src/pages/footer/gestion-cookies.vue")
  },
  {
    name: "footer-mentions-legales",
    path: "/footer/mentions-legales",
    component: () => import("/app/packages/frontend-bo/src/pages/footer/mentions-legales.vue")
  },
  {
    name: "hebergements-type-demandeSejourId-hebergementId",
    path: "/hebergements/:type()/:demandeSejourId()/:hebergementId()",
    meta: _91hebergementId_93fjgi9dU1m2Meta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/hebergements/[type]/[demandeSejourId]/[hebergementId].vue")
  },
  {
    name: "hebergements-type-hebergementId",
    path: "/hebergements/:type()/:hebergementId()",
    meta: _91hebergementId_933fhTUqPKpGMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/hebergements/[type]/[hebergementId].vue")
  },
  {
    name: "hebergements-type",
    path: "/hebergements/:type()",
    meta: indexhJqaX9oOeQMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/hebergements/[type]/index.vue")
  },
  {
    name: "hebergements",
    path: "/hebergements",
    meta: indexwWppGIjTIhMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/hebergements/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQAbM7tiAcvMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/index.vue")
  },
  {
    name: "informations",
    path: "/informations",
    meta: indexImCSGNmFq7Meta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/informations/index.vue")
  },
  {
    name: "messagerie",
    path: "/messagerie",
    meta: indexbUK8nsahbMMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/messagerie/index.vue")
  },
  {
    name: "organismes-organismeId",
    path: "/organismes/:organismeId?",
    meta: _91_91organismeId_93_93pbpOPlw7sIMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/organismes/[[organismeId]].vue")
  },
  {
    name: "organismes-liste",
    path: "/organismes/liste",
    meta: listeYOkek1fj4EMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/organismes/liste.vue")
  },
  {
    name: "sejours-declarationId",
    path: "/sejours/:declarationId()",
    meta: _91declarationId_937n92zEX3FvMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId].vue"),
    children: [
  {
    name: "sejours-declarationId-documents",
    path: "documents",
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId]/documents.vue")
  },
  {
    name: "sejours-declarationId-eig",
    path: "eig",
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId]/eig.vue")
  },
  {
    name: "sejours-declarationId-formulaire",
    path: "formulaire",
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId]/formulaire.vue")
  },
  {
    name: "sejours-declarationId-historique",
    path: "historique",
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId]/historique.vue")
  },
  {
    name: "sejours-declarationId-messagerie",
    path: "messagerie",
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/[declarationId]/messagerie.vue")
  }
]
  },
  {
    name: "sejours",
    path: "/sejours",
    meta: indexJOAtVRTNQwMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/sejours/index.vue")
  },
  {
    name: "territoires-territoireId",
    path: "/territoires/:territoireId?",
    meta: _91_91territoireId_93_93wXUagTSvEGMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/territoires/[[territoireId]].vue")
  },
  {
    name: "territoires-liste",
    path: "/territoires/liste",
    meta: listenKXtYhESagMeta || {},
    component: () => import("/app/packages/frontend-bo/src/pages/territoires/liste.vue")
  }
]